@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

.spacer{
    height: 30px !important;
}

.tableCard{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    padding: 30px 30px 0px 30px;
    text-align: center;
    font-family: 'Poppins';
}

thead
{
    background-color: #333D79FF;
    color: white;
    font-weight: 500;
    /*opacity: 0.4;*/
    opacity: 1;
    font-size: 4vh;
    padding: 2vw;
}

.tableHeading{
    font-weight: 500;
    /*opacity: 0.4;*/
    opacity: 1;
    font-size: 2vh;
    padding: 1vw;
}

tr:nth-child(odd) {background: #f3f3f3; color: black;}
tr:nth-child(even) {background: #333D79FF; color: white;}

th
{
    background-color: #333D79FF;
    color: white;
}

td
{
   /*background-color: #EEA47FFF;*/    
    padding: 0.75vw;
    font-size: 1.25vw;
    font-weight: bold;    
}

.header{
    color: #1256d6	;
}

.playedCard{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    padding: 30px;
    text-align: center;
    font-family: 'Poppins';
    height: 100% !important;
}

.playedCard div{
    height: 100% !important;
}

th{
    font-weight: 500;
    opacity: 0.4;
}

h2
{
    margin-top: 0%;
    width: 100%;
    background-color: #333D79FF;
    color: white;
    text-align: center;
    padding: 1vw;
    font-size: 2vw;
}
