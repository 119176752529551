.header-username{
  /* display: inline-block; */
    float: right;
    color: white;
}

tr.red {
  background: #ffd3d3;
}
tr.green {
  background: #d3ffd3;
}

.text-center{
  text-align:center;
}

.bordered{
  border: 1px solid #ccc;
}
.position-relative{
  position: relative;
}
.position-absolute{
  position: absolute;
}
.p-1{
  padding:10px;
}
.p-2{
  padding:20px;
}
.t-0{
  top:0;
}
.r-0{
  right:0;
}

.ant-layout
{
  background-color: white;
}

.ant-table table {
  border-spacing: 1px;
  text-align: center;
}

.ant-layout-header {
  /*height: 64px;*/
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  /*background: rgb(255, 129, 0);*/
}

.ant-form-item-control{
  border:none;
}

.font-24{
  font-size:24px;
}

.d-flex{
  display: flex;
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: unset; 
}


.installment-page-header
{
  background-color: #3f3f3f;  
}

.engagements-page-header
{
  background-color: #3f3f3f;
}

.scores-page-header
{
  background-color: #3f3f3f;
}

.user-page-header
{
  background-color: #3f3f3f;
}

.class-page-header
{
  background-color: #3f3f3f;
}

.ant-page-header-heading-title
{  
  color: white;    
}

.ant-table-thead > tr > th
{
  background-color: #3f3f3f;  
  color: wheat;
  opacity: 1;
  font-size: 2.5vh;
  text-align: center;
}

.EngagementsTable
{
  display: none;
}


.UsersTable
{
  margin-top: 2vh;  
  border-collapse: separate;    
  border-spacing: 10px;
}

.ant-statistic-title
{
  color: wheat;
}

.ant-statistic-content-value-int
{
  color: #ff8500;
}

.ant-card
{
  border: unset;
}

.InstallmentsTable tr:nth-child(odd) {background: #ff8500; color: white;}
.InstallmentsTable tr:nth-child(even) {background: #3f3f3f; color: white;}

.EngagementsTable tr:nth-child(odd) {background: #ff8500; color: white;}
.EngagementsTable tr:nth-child(even) {background: #3f3f3f; color: white;}

.ScoresTable tr:nth-child(odd) {background: #ff8500; color: white;}
.ScoresTable tr:nth-child(even) {background: #3f3f3f; color: white;}

.ClassesTable tr:nth-child(odd) {background: #ff8500; color: white;}
.ClassesTable tr:nth-child(even) {background: #3f3f3f; color: white;}

.UsersTable tr:nth-child(odd) {background: #ff8500; color: white;}
.UsersTable tr:nth-child(even) {background: #3f3f3f; color: white;}

.ticketdetailsTable tr:nth-child(odd) {background: #ff8500; color: white;}
.ticketdetailsTable tr:nth-child(even) {background: #3f3f3f; color: white;}

.studentstat-table tr:nth-child(odd) {background: #ff8500; color: white;}
.studentstat-table tr:nth-child(even) {background: #3f3f3f; color: white;}

.studentstat-table
{
  text-align: center;  
}