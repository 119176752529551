@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

.spacer{
    height: 30px !important;
}

.tableCard{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    padding: 30px;
    text-align: center;
    font-family: 'Poppins';
}

.header{
    color: #1256d6	;
}

.playedCard{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    padding: 30px;
    text-align: center;
    font-family: 'Poppins';
    height: 100% !important;
}

.playedCard div{
    height: 100% !important;
}

th{
    font-weight: 500;
    opacity: 0.4;
}