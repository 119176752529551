@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

.card{
    border-radius: 20px;
    /*46aef7*/
    background-image: radial-gradient(circle 248px at center, #333D79FF 0%, #333D79FF 47%, #333D79FF 100%) !important;
    /*width: 400px;*/
    width: 270px;
    height: 138px;
    margin-left: 25%;
}

.cardActive{
    border-radius: 20px;
   /*background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%) !important;*/
   background-image: radial-gradient(circle 248px at center, #333D79FF 0%, #333D79FF 47%, #333D79FF 100%) !important;
    /*width: 400px;*/
    width: 270px;
    margin-left: 5% ;
    height: 138px;
}

.imageWarp{
    border-radius: 100%;
    width: 70px;

    height: 70px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}

h3
{
    color: white;
}

.site-layout
{
    background-color: white;
}

.ant-layout-header
{
    background: #001529;
}