@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.common_selected__O7rtI{
    background-color: aquamarine;
}
.DrivingSchoolUserAdd_selectedSlot__zOapO {
    background-color: darkseagreen;
}
.login_form-input__-sXul{
    border: none;
    /* border-bottom:1px solid #ccc; */
}
.header-username{
  /* display: inline-block; */
    float: right;
    color: white;
}

tr.red {
  background: #ffd3d3;
}
tr.green {
  background: #d3ffd3;
}

.text-center{
  text-align:center;
}

.bordered{
  border: 1px solid #ccc;
}
.position-relative{
  position: relative;
}
.position-absolute{
  position: absolute;
}
.p-1{
  padding:10px;
}
.p-2{
  padding:20px;
}
.t-0{
  top:0;
}
.r-0{
  right:0;
}

.ant-layout
{
  background-color: white;
}

.ant-table table {
  border-spacing: 1px;
  text-align: center;
}

.ant-layout-header {
  /*height: 64px;*/
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  /*background: rgb(255, 129, 0);*/
}

.ant-form-item-control{
  border:none;
}

.font-24{
  font-size:24px;
}

.d-flex{
  display: flex;
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: unset; 
}


.installment-page-header
{
  background-color: #3f3f3f;  
}

.engagements-page-header
{
  background-color: #3f3f3f;
}

.scores-page-header
{
  background-color: #3f3f3f;
}

.user-page-header
{
  background-color: #3f3f3f;
}

.class-page-header
{
  background-color: #3f3f3f;
}

.ant-page-header-heading-title
{  
  color: white;    
}

.ant-table-thead > tr > th
{
  background-color: #3f3f3f;  
  color: wheat;
  opacity: 1;
  font-size: 2.5vh;
  text-align: center;
}

.EngagementsTable
{
  display: none;
}


.UsersTable
{
  margin-top: 2vh;  
  border-collapse: separate;    
  border-spacing: 10px;
}

.ant-statistic-title
{
  color: wheat;
}

.ant-statistic-content-value-int
{
  color: #ff8500;
}

.ant-card
{
  border: unset;
}

.InstallmentsTable tr:nth-child(odd) {background: #ff8500; color: white;}
.InstallmentsTable tr:nth-child(even) {background: #3f3f3f; color: white;}

.EngagementsTable tr:nth-child(odd) {background: #ff8500; color: white;}
.EngagementsTable tr:nth-child(even) {background: #3f3f3f; color: white;}

.ScoresTable tr:nth-child(odd) {background: #ff8500; color: white;}
.ScoresTable tr:nth-child(even) {background: #3f3f3f; color: white;}

.ClassesTable tr:nth-child(odd) {background: #ff8500; color: white;}
.ClassesTable tr:nth-child(even) {background: #3f3f3f; color: white;}

.UsersTable tr:nth-child(odd) {background: #ff8500; color: white;}
.UsersTable tr:nth-child(even) {background: #3f3f3f; color: white;}

.ticketdetailsTable tr:nth-child(odd) {background: #ff8500; color: white;}
.ticketdetailsTable tr:nth-child(even) {background: #3f3f3f; color: white;}

.studentstat-table tr:nth-child(odd) {background: #ff8500; color: white;}
.studentstat-table tr:nth-child(even) {background: #3f3f3f; color: white;}

.studentstat-table
{
  text-align: center;  
}
.AP-Dashboard_card__LU7ye{
    border-radius: 20px;
    /*46aef7*/
    background-image: radial-gradient(circle 248px at center, #333D79FF 0%, #333D79FF 47%, #333D79FF 100%) !important;
    /*width: 400px;*/
    width: 270px;
    height: 138px;
    margin-left: 25%;
}

.AP-Dashboard_cardActive__3nrbK{
    border-radius: 20px;
   /*background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%) !important;*/
   background-image: radial-gradient(circle 248px at center, #333D79FF 0%, #333D79FF 47%, #333D79FF 100%) !important;
    /*width: 400px;*/
    width: 270px;
    margin-left: 5% ;
    height: 138px;
}

.AP-Dashboard_imageWarp__1ifP_{
    border-radius: 100%;
    width: 70px;

    height: 70px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}

h3
{
    color: white;
}

.AP-Dashboard_site-layout__h0N6p
{
    background-color: white;
}

.AP-Dashboard_ant-layout-header__30iqk
{
    background: #001529;
}
.AP-TrafficTuesdayUpdates_spacer__4fLDX{
    height: 30px !important;
}

.AP-TrafficTuesdayUpdates_tableCard__1gHvx{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    padding: 30px;
    text-align: center;
    font-family: 'Poppins';
}

.AP-TrafficTuesdayUpdates_header__2Cy8_{
    color: #1256d6	;
}

.AP-TrafficTuesdayUpdates_playedCard__2s923{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    padding: 30px;
    text-align: center;
    font-family: 'Poppins';
    height: 100% !important;
}

.AP-TrafficTuesdayUpdates_playedCard__2s923 div{
    height: 100% !important;
}

th{
    font-weight: 500;
    opacity: 0.4;
}
.AP-DailyUpdates_timerContainer__1vrje{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: silver;
}
.AP-DailyUpdates_timerImage__EiJua{
    height:90px;
}

.AP-DailyUpdates_tableCard__xr-TW{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    /*padding: 30px 30px 0px 30px;*/
    padding: 0px 0px 0px 0px;
    text-align: center;
    font-family: 'Poppins';
}

.AP-DailyUpdates_header__33xHi{
    color: white;
    /*margin-left: 3vw;*/
    text-align: left;
    font-size: 1.75vw;
}

.AP-DailyUpdates_playedCard__1dxKz{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    padding: 1vw;
    text-align: center;
    font-family: 'Poppins';
    height: 100% !important;
}

.AP-DailyUpdates_playedCard__1dxKz div{
    height: 100% !important;
}

thead
{
    background-color: #333D79FF;
    color: white;
    font-weight: 500;
    /*opacity: 0.4;*/
    opacity: 1;
    font-size: 4vh;
    padding: 2vw;
}

.AP-DailyUpdates_tableHeading__3y8eY{
    font-weight: 500;
    /*opacity: 0.4;*/
    opacity: 1;
    font-size: 2vh;
    padding: 1vw;
}

tr:nth-child(odd) {background: #f3f3f3; color: black;}
tr:nth-child(even) {background: #333D79FF; color: white;}

th
{
    background-color: #333D79FF;
    color: white;
}

td
{
   /*background-color: #EEA47FFF;*/    
    padding: 0.75vw;
    font-size: 1.25vw;
    font-weight: bold;    
    opacity: 1;
}

.AP-DailyUpdates_title__y1RqC
{
    background-color: #333D79FF;
    color: white;
    /* border-radius: 8px; */
    font-size: 2vw;
    /* width: 20vw; */
    /* margin-left: 3vw; */
    padding: 1vw 4vw 1vw 4vw;
    margin-top: 0vh;
}

.AP-DailyUpdates_QuantitativeTH__3NzqD
{
    background-color: #333D79FF;
    opacity: 1;
    color: white;
    font-size: 1.5vw;
    padding: 2vw;
    font-weight: 500;
}

.AP-DailyUpdates_MostPlayedDiv__1TuF7
{
    margin-top: -34vh;
    background-color: aliceblue;
    width: 40vw;
    margin-left: 53vw;
    padding: 2vw;
    text-align: end;
}

.AP-DailyUpdates_RakshakLogo__2Fccl
{
    width: 7%;
    height: auto;
    margin-top: 0vh;    
    margin-left: 91%;
}

.AP-DailyUpdates_TimerImg__9ef5c
{
    width: 8%;
    height: auto;
    margin-top: -26vh;
    margin-bottom: 2vh;
    margin-left: 7vw;
    position: absolute;
}
.AP-AssessmentScores_spacer__27lJo{
    height: 30px !important;
}

.AP-AssessmentScores_tableCard__3MkqM{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    padding: 30px;
    text-align: center;
    font-family: 'Poppins';
}

.AP-AssessmentScores_header__1LCRF{
    color: #1256d6	;
}

.AP-AssessmentScores_playedCard__15Mny{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    padding: 30px;
    text-align: center;
    font-family: 'Poppins';
    height: 100% !important;
}

.AP-AssessmentScores_playedCard__15Mny div{
    height: 100% !important;
}

th{
    font-weight: 500;
    opacity: 0.4;
}
.AP-EmployeeStats_spacer__vlLOO{
    height: 30px !important;
}

.AP-EmployeeStats_tableCard__3B3BY{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    padding: 30px 30px 0px 30px;
    text-align: center;
    font-family: 'Poppins';
}

thead
{
    background-color: #333D79FF;
    color: white;
    font-weight: 500;
    /*opacity: 0.4;*/
    opacity: 1;
    font-size: 4vh;
    padding: 2vw;
}

.AP-EmployeeStats_tableHeading__1O3dW{
    font-weight: 500;
    /*opacity: 0.4;*/
    opacity: 1;
    font-size: 2vh;
    padding: 1vw;
}

tr:nth-child(odd) {background: #f3f3f3; color: black;}
tr:nth-child(even) {background: #333D79FF; color: white;}

th
{
    background-color: #333D79FF;
    color: white;
}

td
{
   /*background-color: #EEA47FFF;*/    
    padding: 0.75vw;
    font-size: 1.25vw;
    font-weight: bold;    
}

.AP-EmployeeStats_header__3wY1h{
    color: #1256d6	;
}

.AP-EmployeeStats_playedCard__BBo0f{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    padding: 30px;
    text-align: center;
    font-family: 'Poppins';
    height: 100% !important;
}

.AP-EmployeeStats_playedCard__BBo0f div{
    height: 100% !important;
}

th{
    font-weight: 500;
    opacity: 0.4;
}

h2
{
    margin-top: 0%;
    width: 100%;
    background-color: #333D79FF;
    color: white;
    text-align: center;
    padding: 1vw;
    font-size: 2vw;
}

