@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

.timerContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: silver;
}
.timerImage{
    height:90px;
}

.tableCard{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    /*padding: 30px 30px 0px 30px;*/
    padding: 0px 0px 0px 0px;
    text-align: center;
    font-family: 'Poppins';
}

.header{
    color: white;
    /*margin-left: 3vw;*/
    text-align: left;
    font-size: 1.75vw;
}

.playedCard{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 10px !important;
    padding: 1vw;
    text-align: center;
    font-family: 'Poppins';
    height: 100% !important;
}

.playedCard div{
    height: 100% !important;
}

thead
{
    background-color: #333D79FF;
    color: white;
    font-weight: 500;
    /*opacity: 0.4;*/
    opacity: 1;
    font-size: 4vh;
    padding: 2vw;
}

.tableHeading{
    font-weight: 500;
    /*opacity: 0.4;*/
    opacity: 1;
    font-size: 2vh;
    padding: 1vw;
}

tr:nth-child(odd) {background: #f3f3f3; color: black;}
tr:nth-child(even) {background: #333D79FF; color: white;}

th
{
    background-color: #333D79FF;
    color: white;
}

td
{
   /*background-color: #EEA47FFF;*/    
    padding: 0.75vw;
    font-size: 1.25vw;
    font-weight: bold;    
    opacity: 1;
}

.title
{
    background-color: #333D79FF;
    color: white;
    /* border-radius: 8px; */
    font-size: 2vw;
    /* width: 20vw; */
    /* margin-left: 3vw; */
    padding: 1vw 4vw 1vw 4vw;
    margin-top: 0vh;
}

.QuantitativeTH
{
    background-color: #333D79FF;
    opacity: 1;
    color: white;
    font-size: 1.5vw;
    padding: 2vw;
    font-weight: 500;
}

.MostPlayedDiv
{
    margin-top: -34vh;
    background-color: aliceblue;
    width: 40vw;
    margin-left: 53vw;
    padding: 2vw;
    text-align: end;
}

.RakshakLogo
{
    width: 7%;
    height: auto;
    margin-top: 0vh;    
    margin-left: 91%;
}

.TimerImg
{
    width: 8%;
    height: auto;
    margin-top: -26vh;
    margin-bottom: 2vh;
    margin-left: 7vw;
    position: absolute;
}